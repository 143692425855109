<template>
  <div class="disclaimers">
    <el-card class="disclaimersCard" shadow="always">
      <div slot="header" class="clearfix">
        <span class="disclaimersCard_content_title"> {{ $t('DisclaimersTitle') }}</span>

      </div>
      <div  class="disclaimersCard_content">
        <div class="disclaimersCard_content_item">

          {{ $t('DisclaimersContent1') }}
        </div>
        <br />

        <div class="disclaimersCard_content_item">
          {{ $t('DisclaimersContent2') }}

        </div>
        <br />

        <div class="disclaimersCard_content_item">
          {{ $t('DisclaimersContent3') }}

        </div>
        <br />

        <div class="disclaimersCard_content_item">
          {{ $t('DisclaimersContent4') }}

        </div>
        <br />

        <div class="disclaimersCard_content_item">
          {{ $t('DisclaimersContent5') }}

        </div>

      </div>
    </el-card>
  </div>
</template>
  
<script>
// @ is an alias to /src
import { mapState } from "vuex";

export default {
  name: "Disclaimers",
  computed: {
    ...mapState(["mobileFlag", "lang"]),
  },
  data() {
    return {

    };
  },
  created() {

  },
  methods: {

  },
};
</script>
<style scoped lang="less">
.disclaimersCard {
  width: 80%;
  margin: 50px auto;

  .disclaimersCard_content_title {
    font-size: 20px;
    margin: 0 auto;
    display: block;
    text-align: center;
  }

  .disclaimersCard_content {
    font-size: 18px;
    text-indent: 2em;
    line-height: 28px;
  }
}
@media screen and (max-width: 767px) {
  .disclaimersCard {
    width: calc( 100% - 32px);
    margin-top: 20px;
    .disclaimersCard_content {
      font-size: 16px;
      text-indent: 2em;
      line-height: 28px;
    }
    
  }
}
</style>
  